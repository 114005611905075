<template>
    <div>
      <div class="compont_p">我的足迹</div>
      <el-table :data="tableData" style="margin-top: 20px">
        <el-table-column prop="name" label="商品信息" width="260">
          <template slot-scope="{ row }">
            <div class="flex_row_aic" @click="handleClick(row)">
              <img style="width: 87px; height: 87px" :src="row.image.url" alt="" />
              <div class="info">
                <p class="shenglue_2">{{ row.title }}</p>
                <p class="c6">{{ row.specification }}</p>
                <div class="flex_row">
                  <div class="tag" v-for="item in row.tags" :key="item.title" :style="`color: #${item.color}; border: 1px solid #${item.color};`">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="厂家" align="center" width="150">
          <template slot-scope="{ row }">
            <span class="shenglue_1">{{ row.manufacturer }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="validity_date" label="效期" align="center" />
        <el-table-column  label="单价"  align="center" >
        <template slot-scope="{ row }">
            <span class="shenglue_1" style="color:red">{{ row.price }}
            <span v-if="row.price1>0" style="margin-left: 5px;text-decoration: line-through;color:rgb(116, 113, 113);font-size: 12px;">{{row.price1}}</span>
            </span>
        </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <i class="el-icon-shopping-cart-2" @click="handleAdd(scope.row)"></i>
            <i class="el-icon-delete" @click="handleDelete(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
      <pagination :total="total" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
    </div>
  </template>
  
  <script>
  import pagination from "@/components/pagination.vue";
  import { handleData } from "@/utils";
  export default {
    components: {
      pagination,
    },
    data() {
      return {
        tableData: [],
        total: 0,
        page: 1,
        page_size: 10,
      };
    },
    created() {
      this.getCollect();
    },
    methods: {
      getCollect() {
        this.$api("goods.getGoods", {
            discover_type:102,
            page: this.page,
            page_size: this.page_size,
        }).then((res) => {
          this.tableData = res.data;
          this.total = res.listTotal;
        });
      },
      handleAdd(row) {
        if (row.can_buy == 102) {
          this.$message.error(row.can_buy_str);
          return;
        }
        this.$api("goods.addCart", { id: row.id, number: row.step }).then(() => {
          this.$message.success("加入购物车成功");
          this.$store.dispatch("getBadge");
        });
      },
      async handleDelete(row) {
        await handleData("account.delCollect", { id: row.id }, "删除数据");
        this.getCollect();
      },
      handleCurrentChange(val) {
        this.page = val;
        this.getCollect();
      },
      handleSizeChange(val) {
        this.page_size = val;
        this.getCollect();
      },
      onSubmit() {},
    },
  };
  </script>
  
  <style lang="less" scoped>
  .info {
    text-align: left;
    margin-left: 10px;
  
    .tag {
      padding: 1px 8px;
      height: 17px;
      line-height: 17px;
      text-align: center;
      border-radius: 2px;
      margin-right: 10px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
    }
    .c6 {
      font-size: 12px;
    }
  }
  .el-icon-shopping-cart-2 {
    font-size: 21px;
    color: @themeColor;
    margin-right: 15px;
    cursor: pointer;
  }
  .el-icon-delete {
    font-size: 20px;
    color: #ff4c4c;
    cursor: pointer;
  }
  </style>
  